import React from 'react'
import Gallery from '../components/Gallery'
import cutups from '../data/gallery-cutups'

export default props =>{
  return (
    <>
      <Gallery photos={cutups}/>
    </>
  );
}
