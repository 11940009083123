import React, { useState, useEffect } from 'react';

import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'
import LazyImage from './LazyImage'

export default props => {
  const { photos } = props
  const [ currentImage, setCurrentImage ] = useState(0);
  const [ lightboxIsOpen, setLightboxIsOpen ] = useState(false);

  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index)
    setLightboxIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage(0)
    setLightboxIsOpen(false)
  }

  const gotoPrevious = () => {
    setCurrentImage(currentImage-1)
  }

  const gotoNext = () => {
    setCurrentImage(currentImage+1)
  }

  return (
    <>
      <Gallery photos={photos} onClick={openLightbox} renderImage={LazyImage} />
      <Lightbox images={photos}
          onClose={closeLightbox}
          onClickPrev={gotoPrevious}
          onClickNext={gotoNext}
          currentImage={currentImage}
          isOpen={lightboxIsOpen}
        />
    </>
  );
}
