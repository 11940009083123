import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazy-load'
import ImageLoader from './ImageLoader'

export default ({
  index,
  onClick,
  photo,
}) => {

  return (
    <>
    <LazyLoad 
      width={photo.width}
      height={photo.height}
      debounce={false}
      offsetVertical={500}
      >
      <ImageLoader photo={photo} clicked={e => onClick(e, { index, photo })} />
    </LazyLoad>
    </>
  );
}