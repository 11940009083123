import React from 'react';
import Main from './views/Main'

function App() {
  return (
    <Main />
  );
}

export default App;
