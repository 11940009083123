export default [
  {
    id: "00_IMG_0159",
    src: "images/cutups/00_IMG_0159.jpg",
    width: 1,
    height: 1
  },
  {
    id: "01_IMG_0160",
    src: "images/cutups/01_IMG_0160.jpg",
    width: 1,
    height: 1
  },
  {
    id: "02_IMG_0785",
    src: "images/cutups/02_IMG_0785.jpg",
    width: 1,
    height: 1
  },
  { id: "2011", src: "images/cutups/2011.jpg", width: 1, height: 1 },
  { id: "aggedpoke", src: "images/cutups/aggedpoke.jpg", width: 1, height: 1 },
  { id: "alpstreet", src: "images/cutups/alpstreet.jpg", width: 1, height: 1 },
  {
    id: "arcskletal",
    src: "images/cutups/arcskletal.jpg",
    width: 1,
    height: 1
  },
  { id: "banquet", src: "images/cutups/banquet.jpg", width: 1, height: 1 },
  { id: "bunkerone", src: "images/cutups/bunkerone.jpg", width: 1, height: 1 },
  {
    id: "candycircus",
    src: "images/cutups/candycircus.jpg",
    width: 1,
    height: 1
  },
  {
    id: "creedverse",
    src: "images/cutups/creedverse.jpg",
    width: 1,
    height: 1
  },
  {
    id: "cutresorts",
    src: "images/cutups/cutresorts.jpg",
    width: 1,
    height: 1
  },
  { id: "flamenco", src: "images/cutups/flamenco.jpg", width: 1, height: 1 },
  { id: "goatstare", src: "images/cutups/goatstare.jpg", width: 1, height: 1 },
  {
    id: "gospelwall",
    src: "images/cutups/gospelwall.jpg",
    width: 1,
    height: 1
  },
  {
    id: "hartroulette",
    src: "images/cutups/hartroulette.jpg",
    width: 1,
    height: 1
  },
  { id: "hijrozem", src: "images/cutups/hijrozem.jpg", width: 1, height: 1 },
  { id: "hoodat", src: "images/cutups/hoodat.jpg", width: 1, height: 1 },
  {
    id: "icebergspirit",
    src: "images/cutups/icebergspirit.jpg",
    width: 1,
    height: 1
  },
  {
    id: "jackofdiamonds",
    src: "images/cutups/jackofdiamonds.jpg",
    width: 1,
    height: 1
  },
  { id: "landslide", src: "images/cutups/landslide.jpg", width: 1, height: 1 },
  {
    id: "lastresort",
    src: "images/cutups/lastresort.jpg",
    width: 1,
    height: 1
  },
  { id: "ledgeons", src: "images/cutups/ledgeons.jpg", width: 1, height: 1 },
  { id: "lovecraft", src: "images/cutups/lovecraft.jpg", width: 1, height: 1 },
  { id: "marsdrone", src: "images/cutups/marsdrone.jpg", width: 1, height: 1 },
  { id: "mineaero", src: "images/cutups/mineaero.jpg", width: 1, height: 1 },
  { id: "moonchase", src: "images/cutups/moonchase.jpg", width: 1, height: 1 },
  {
    id: "mountainman",
    src: "images/cutups/mountainman.jpg",
    width: 1,
    height: 1
  },
  {
    id: "mountainterror",
    src: "images/cutups/mountainterror.jpg",
    width: 1,
    height: 1
  },
  { id: "mystalone", src: "images/cutups/mystalone.jpg", width: 1, height: 1 },
  { id: "newszero", src: "images/cutups/newszero.jpg", width: 1, height: 1 },
  { id: "outericon", src: "images/cutups/outericon.jpg", width: 1, height: 1 },
  {
    id: "pearlthought",
    src: "images/cutups/pearlthought.jpg",
    width: 1,
    height: 1
  },
  { id: "quakesone", src: "images/cutups/quakesone.jpg", width: 1, height: 1 },
  {
    id: "quarryzone",
    src: "images/cutups/quarryzone.jpg",
    width: 1,
    height: 1
  },
  { id: "rebirth", src: "images/cutups/rebirth.jpg", width: 1, height: 1 },
  {
    id: "redflightby",
    src: "images/cutups/redflightby.jpg",
    width: 1,
    height: 1
  },
  { id: "redpartun", src: "images/cutups/redpartun.jpg", width: 1, height: 1 },
  { id: "redstatue", src: "images/cutups/redstatue.jpg", width: 1, height: 1 },
  { id: "redwonder", src: "images/cutups/redwonder.jpg", width: 1, height: 1 },
  {
    id: "reliefangel",
    src: "images/cutups/reliefangel.jpg",
    width: 1,
    height: 1
  },
  { id: "rubymine", src: "images/cutups/rubymine.jpg", width: 1, height: 1 },
  {
    id: "saphireburiqal",
    src: "images/cutups/saphireburiqal.jpg",
    width: 1,
    height: 1
  },
  { id: "shoegraff", src: "images/cutups/shoegraff.jpg", width: 1, height: 1 },
  {
    id: "splashformd",
    src: "images/cutups/splashformd.jpg",
    width: 1,
    height: 1
  },
  {
    id: "straightwonder",
    src: "images/cutups/straightwonder.jpg",
    width: 1,
    height: 1
  },
  {
    id: "weatherbig_1",
    src: "images/cutups/weatherbig_1.jpg",
    width: 1,
    height: 1
  },
  {
    id: "wishfulthought",
    src: "images/cutups/wishfulthought.jpg",
    width: 1,
    height: 1
  },
  { id: "wol", src: "images/cutups/wol.jpg", width: 1, height: 1 },
  { id: "worshipone", src: "images/cutups/worshipone.jpg", width: 1, height: 1 }
];
