import React, { useState, useEffect } from 'react';

export default ({ photo, clicked }) => {

  const [ className, setClassName ] = useState('img-loading');

  const onLoad = () => {
    setClassName('img-loaded')
  };

  return (
    <>
      <img onClick={clicked}
        {...photo}
        className={className} 
        onLoad={()=>onLoad()}
        />
    </>
  );
}